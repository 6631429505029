import React from "react";


export const Checkout = () => {
    return (
        <div className="checkout-page">
            <h1>Waiting for payment confirmation...</h1>
            <p>Please complete the payment</p>
            <p>Make sure you send the full payment amount to the address to ensure the product delivery process goes through.</p>
        </div>
    );
};
