// src/components/Header.jsx
import React from "react";
import logo from "../assets/dimesmgmt2.png"; // Import your logo image
import "./header.css"; // Import any specific styling for the header

export const Header = () => {
    return (
        <div className="header">
            <img src={logo} alt="Logo" className="logo" />
        </div>
    );
};
