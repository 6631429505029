import React from 'react';
import './benefits.css'; // Ensure benefits.css contains the shining effect styling
import redplug from "../assets/redplug.png"; // Import the image

export const Benefits = () => {
  const benefits = [
    'Cookies included so that it appears as if you are logging in from the same device that these are farmed on',
    'Having real alternative accounts to boost your submissions.',
    'Organically created and farmed accounts using US mobile proxies zero fraud score',
    'Guaranteed replacement or refund if accounts face instant shadowban',
    '24/7 customer support for all your queries',
    'Being able to post in all subreddits that require you to have enough karma.',
    '48HR warranty',
  ];

  return (
    <div className="benefits">
      <h2 className="benefits-title shining-text-ben">Why buy from us?</h2>
      <div className="benefits-content">
        <img
          src={redplug}
          alt="Red Plug"
          className="benefits-image"
        />
        <ul className="benefits-list">
          {benefits.map((benefit, index) => (
            <li key={index} className="benefit-item">
              {benefit}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
