import product10 from "./assets/violet.png"
import product11 from "./assets/claire.png"
import product12 from "./assets/amelia.png"
import product13 from "./assets/clara.png"
import product14 from "./assets/grace.png"
import product15 from "./assets/reese.png"
import product16 from "./assets/aria.png"
import productlexi from "./assets/lexi.png"
import productnina from "./assets/nina.png"
import productRuby from "./assets/Ruby.png"
import productskyla from "./assets/skyla.png"
import productZoe from "./assets/zoe.png"
import productlily from "./assets/lily.png"
import productLilyana from "./assets/Lilyana.png"
import productAnnee from "./assets/Annee.png"
import productHope from "./assets/Hope.png"

export const PRODUCTS = [
 
  {
     id: 889,
    productName: "Lilyana",
    price: 70.0,
    productImage: productLilyana,
  },

];



//{
   // id: 755,
//    productName: "Ruby",
  //  price: 50.0,
  //  productImage: productRuby,
//},