// src/pages/Contact.jsx
import React from "react";
import "./contact.css";
import { Header } from "../../components/header"; // Import Header

export const Contact = () => {
    return (
        <div className="contact-container">
            <Header />
            <h1>Contact Us</h1>
            <p>Feel free to reach out with any questions or feedback!</p>
            <button
                className="telegram-button"
                onClick={() => window.open("https://t.me/DimesReddAccs", "_blank")}
            >
                Contact Us on Telegram
            </button>
        </div>
    );
};
