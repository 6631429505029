// src/components/CustomerReviews.jsx
import React from "react";
import "./reviews.css"; // Ensure this file contains the shining effect styling

const reviews = [
  {
    id: 1,
    name: "TODOR*V - from Telegram",
    review:
      "Dimes has the best reddit accounts in the game always delivers with high quality karma and speedy results BIG VOUCH, spent 135 for 3 accs",
  },
  {
    id: 2,
    name: "Majeta St**ic - from Telegram",
    review: "Thank you for the account, delivery + answer was really fast 👍🏻",
  },
  {
    id: 3,
    name: "Palve Djo****ic - from Telegram",
    review:
      "Just bought 2 accs from Dimes both work tottaly fine as well as the emails, great customer support as well🤝💪",
  },
  {
    id: 4,
    name: "Velizar Dim****ov - from Telegram",
    review:
      "I purchased a Reddit account from Dimes, and I was impressed with how smooth and secure the process was. The account was exactly as described, with a solid history and karma. The seller communicated well, answered all my questions, and made the transfer easy. Highly recommend Adam for a trustworthy and hassle-free experience 🔥",
  },
  {
    id: 5,
    name: "Ni***nEX - from Telegram",
    review:
      "Dimes sent me the Reddit accounts immediately, gave me instructions on the accounts, supplied cookies with the accounts, and did not require a MM to provide the accounts. I sent half up front and he sent the first account. Trustworthy and reliable :)",
  },
  {
    id: 6,
    name: "Ob****on Empire - from Telegram",
    review: "so far i have bought 3 accounts from Dimes and everything has been perfect :)))",
  },
  {
    id: 7,
    name: "emi - from Telegram",
    review: "W Accounts provider💪🏼🥷",
  },
];

export const CustomerReviews = () => {
  return (
    <div className="reviews-section">
      <h2 className="shining-text-rev">What our customers say...</h2>
      <div className="reviews-list">
        {reviews.map((review) => (
          <div key={review.id} className="review-card">
            <h3>{review.name}</h3>
            <p>{review.review}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
