// src/components/Footer.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

export const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-links">
                <Link to="/">Shop</Link>
                <Link to="/contact">Contact</Link>
                <Link to="/">Terms & Conditions</Link>
                <Link to="/">Privacy Policy</Link>
            </div>
            <p className="footer-copy">&copy; Copyright 2024, Dimesmgmt All rights reserved.</p>
        </div>
    );
};
