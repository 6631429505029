import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";
import "./cart-item.css";

export const CartItem = (props) => {
  const { id, productName, price, productImage } = props.data;
  const { cartItems, removeFromCart } = useContext(ShopContext);

  return (
    <div className="cartItem">
      <img src={productImage} alt={productName} />
      <div className="description">
        <p><b>{productName}</b></p>
        <p><b>${price}</b></p>
        <div className="countHandler">
          <button 
            onClick={() => removeFromCart(id)} 
            disabled={cartItems[id] <= 0}>
            -
          </button>
          <input 
            value={cartItems[id]} 
            readOnly // Disable manual user input
          />
        </div>
      </div>
    </div>
  );
};
