import React from 'react';

export const Success = () => {
  return (
    <div>
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase. Please check your email inbox for product information. Note: It may be inside your spam inbox.</p>
    </div>
  );
};
