import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/navbar';
import { Footer } from './components/footer'; // Import Footer
import { Shop } from './pages/shop/shop';
import { Cart } from './pages/cart/cart';
import { Checkout } from './pages/checkout/checkout';
import { Elements } from "@stripe/react-stripe-js"; // Import Elements
import { Success } from './pages/success/success';
import { Cancel } from './pages/cancel/cancel';
import SignIn from './pages/signin/signin';
import SignUp from './pages/signup/signup';
import { Contact } from "./pages/contact/contact";
import { ShopContextProvider } from './context/shop-context';
import { inject } from '@vercel/analytics'; // Import the inject function from Vercel Analytics
import { stripePromise } from './stripe'; // Import stripePromise from your stripe.js setup


// Inject the Vercel Analytics script
inject();

function App() {
  return (
    <div className="App"> 
      <ShopContextProvider>
        <Router> 
          <Navbar />
          <Routes>
            <Route path="/" element={<Shop />} />
            
            {/* Wrap the Cart route with Elements provider */}
            <Route 
              path="/cart" 
              element={
                <Elements stripe={stripePromise}> 
                  <Cart /> 
                </Elements>
              } 
            />
            
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/signin" element={<SignIn />} /> 
            <Route path="/signup" element={<SignUp />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer /> {/* Add Footer here */}
        </Router>
      </ShopContextProvider>
    </div>
  );
}

export default App;
