import React, { useContext, useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js"; // Stripe.js
import { useElements } from "@stripe/react-stripe-js"; // Stripe Elements
import axios from "axios";
import { PRODUCTS } from "../../products";
import { ShopContext } from "../../context/shop-context";
import { CartItem } from "./cart-item";
import "./cart.css";
import { useNavigate } from "react-router-dom";



export const Cart = () => {
  const { cartItems, getTotalCartAmount, removeItemFromCart } = useContext(ShopContext);
  const totalAmount = getTotalCartAmount();
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [dynamicStyle, setDynamicStyle] = useState({ fixedButtons: {}, checkout: {} });
  const cartItemsRef = useRef(null);

  const [selectedCrypto, setSelectedCrypto] = useState("btc"); // Default to Bitcoin
  const elements = useElements(); // Use Elements to access payment elements

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    if (email) {
      setUserEmail(email);
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);

      if (cartItemsRef.current) {
        const cartItemsRect = cartItemsRef.current.getBoundingClientRect();
        setDynamicStyle({
          fixedButtons: {
            top: `${cartItemsRect.top + window.scrollY}px`,
            left: `${cartItemsRect.right + 20}px`,
          },
          checkout: {
            top: `${cartItemsRect.top + window.scrollY}px`,
            left: `${cartItemsRect.left}px`,
            transform: "translateX(-50%)",
          },
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);






  const handleStripePayment = async () => {
    const token = localStorage.getItem("token");
    const userEmail = localStorage.getItem("userEmail");
  
    if (!token || !userEmail) {
      alert("Please sign in to complete your purchase.");
      return;
    }
  
    try {
      const productIds = cartItemsWithQuantity.map((product) => product.id);
  
      // Call the backend to create the PaymentIntent
      const response = await axios.post(
        "https://my-react-app-iwkb.onrender.com/create-payment-intent",
        {
          amount: totalAmount,
          currency: "usd",
          productIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const { clientSecret } = response.data;
  
      // Use the existing stripePromise (already initialized outside the component)
      const stripe = await stripePromise; // Use the already initialized stripePromise here
  
      // Use the elements context created by the <Elements> provider
      const { error } = await stripe.confirmPayment({
        elements, // Use the elements from the context provided by <Elements>
        clientSecret,
      });
  
      if (error) {
        console.error("Payment failed:", error.message);
        alert("Payment failed, please try again.");
      } else {
        console.log("Payment successful!");
        navigate("/checkout", { state: response.data }); // Redirect to checkout or success page
      }
    } catch (err) {
      console.error("Error initiating Stripe payment:", err.message);
      alert("Failed to initiate payment. Please try again.");
    }
  };
  
  


  

  const handleCryptoPayment = async () => {
    const token = localStorage.getItem("token");
    const userEmail = localStorage.getItem("userEmail");
  
    if (!token || !userEmail) {
      alert("Please sign in to complete your purchase.");
      return;
    }
  
    try {
      const productIds = cartItemsWithQuantity.map((product) => product.id);
  
      const response = await axios.post(
        "https://my-react-app-iwkb.onrender.com/create-payment/",
        {
          amount: totalAmount,
          currency: "usd",
          productIds,
          payCurrency: selectedCrypto, // Send selected cryptocurrency
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const { invoice_url } = response.data;
  
      window.open(invoice_url, "_blank");
      navigate("/checkout", { state: response.data });
    } catch (err) {
      if (err.code === "ECONNRESET") {
        alert("The server is currently asleep. Please wait a minute for it to wake up and try again.");
        return;
      }
  
      if (err.response?.data?.error) {
        console.error("Error response:", err.response.data);
        const errorMessage = err.response.data.error; // Extract error message
        alert(errorMessage); // Display error message in the alert
  
        const soldItems = err.response.data.soldItems || [];
        soldItems.forEach((item) => removeItemFromCart(item.Product_ID));
      } else {
        console.error("Error processing payment:", err.message);
        setErrorMessage("Failed to initiate payment. Please try again.");
        alert("Failed to initiate payment. Please try again.");
      }
    }
  };
  
  

  const cartItemsWithQuantity = PRODUCTS.filter((product) => cartItems[product.id] > 0);
  const [showCryptoDropdown, setShowCryptoDropdown] = useState(false);


  return (
    <div className="cart">
      <div className="cart-header">
        <h1>Your Cart Items</h1>
        {cartItemsWithQuantity.length > 0 && (
          <p className="account-info-message">
            {userEmail
              ? `Successfully signed in. Product information will be emailed to you upon payment confirmation📥`
              : "Please make sure you're signed in before purchasing."}
          </p>
        )}
      </div>

      {totalAmount > 0 && (
        <div className="checkout" style={dynamicStyle.checkout}>
          <b>
            <p>Subtotal: ${totalAmount.toFixed(2)}</p>
          </b>
          {/* Add Crypto Dropdown */}
          
        </div>
      )}

      <div className="cartItems" ref={cartItemsRef}>
        {cartItemsWithQuantity.length > 0 ? (
          cartItemsWithQuantity.map((product) => (
            <CartItem data={product} key={product.id} quantity={cartItems[product.id]} />
          ))
        ) : (
          <div className="empty-cart">
            <h1>Your cart is empty!</h1>
            <button className="back-to-shop-btn" onClick={() => navigate("/")}>
              Back to Shop
            </button>
          </div>
        )}

        {isMobile && cartItemsWithQuantity.length > 0 && (
          <div className="mobile-buttons">
            <button className="action-button stripe-button" onClick={handleStripePayment}>
              Pay with Stripe
            </button>
            <button className="action-button crypto-button" onClick={handleCryptoPayment}>
              Pay with Crypto
            </button>
            <button className="action-button shop-button" onClick={() => navigate("/")}>
              Continue Shopping
            </button>
          </div>
        )}
      </div>

      {!isMobile && cartItemsWithQuantity.length > 0 && (
  <div className="fixed-buttons" style={dynamicStyle.fixedButtons}>
    <button className="action-button stripe-button" onClick={handleStripePayment}>
      Pay with Stripe
    </button>

    {/* Pay with Crypto Button */}
    <div className="crypto-dropdown-container">
      <button
        className="action-button crypto-button"
        onClick={() => setShowCryptoDropdown(!showCryptoDropdown)} // Toggle dropdown visibility
      >
        Pay with Crypto
      </button>

      {/* Dropdown Menu */}
      {showCryptoDropdown && (
        <div className="crypto-dropdown">
          <label htmlFor="cryptoSelect">Choose your cryptocurrency:</label>
          <select
            id="cryptoSelect"
            value={selectedCrypto}
            onChange={(e) => setSelectedCrypto(e.target.value)}
          >
            <option value="btc">Bitcoin (BTC)</option>
            <option value="eth">Ethereum (ETH)</option>
            <option value="usdttrc20">Tether TRC20 (USDT)</option>
            <option value="usdterc20">Tether ERC20 (USDT)</option>
            <option value="sol">Solana (SOL)</option>
            <option value="ltc">Litecoin (LTC)</option>
            
          </select>
          <button
            className="action-button crypto-confirm-button"
            onClick={handleCryptoPayment}
          >
            Confirm
          </button>
        </div>
      )}
    </div>

    <button className="action-button shop-button" onClick={() => navigate("/")}>
      Continue Shopping
    </button>
  </div>
)}

    </div>
  );
};
