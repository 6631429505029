import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Header } from '../../components/header'; // Import Header

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle sign-in submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error

    const { email, password } = formData;

    try {
      const response = await axios.post('https://my-react-app-iwkb.onrender.com/api/auth/login', {
        email,
        password,
      });

      // Store the JWT token and email in localStorage
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('userEmail', email);

      // Redirect the user after successful login
      navigate('/cart'); // Redirect to the cart or any protected route
    } catch (err) {
      setError(err.response?.data?.message || 'Invalid email or password');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
      <form onSubmit={handleSubmit} style={{ maxWidth: '400px', width: '100%' }}>
        <Header />
        <h2>Sign In</h2>
        <div style={{ marginBottom: '15px' }}>
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            required
            style={{ width: '100%', padding: '8px', marginTop: '5px' }}
          />
        </div>
        <button
          type="submit"
          style={{
            width: '200px',
            padding: '10px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginLeft: '10px', // Adjust this value to control the shift
          }}
        >
          Sign In
        </button>

        {/* Display error message */}
        {error && (
          <p style={{ color: 'red', marginTop: '15px', textAlign: 'center' }}>
            {error}
          </p>
        )}

        {/* Link to Sign Up page */}
        <p style={{ marginTop: '20px', textAlign: 'center' }}>
          Don’t have an account? <Link to="/signup" style={{ color: '#007bff' }}>Sign Up</Link>
        </p>
      </form>
    </div>
  );
};

export default SignIn;
