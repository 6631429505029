import React from 'react';

export const Cancel = () => {
  return (
    <div>
      <h1>Payment Canceled</h1>
      <p>Your payment was not completed. Please try again or contact support if you have any issues.</p>
    </div>
  );
};
